export const mediaBreakpoints = {
  largePhone: 480,
  tablet: 768,
  smallDesktop: 992,
  desktop: 1180,
  largeDesktop: 1400,
};

export const mediaSizes = {
  s: 's', // 320 - 768      / PHONE
  m: 'm', // 768 - 1180     / TABLET
  l: 'l', // 1200 - ∞       / DESKTOP
};

export const media = {
  largePhone: `min-width: ${mediaBreakpoints.largePhone}px`,
  tablet: `min-width: ${mediaBreakpoints.tablet}px`,
  smallDesktop: `min-width: ${mediaBreakpoints.smallDesktop}px`,
  desktop: `min-width: ${mediaBreakpoints.desktop}px`,
  largeDesktop: `min-width: ${mediaBreakpoints.largeDesktop}px`,
};

export const sizes = { xxs: 'xxs', xs: 'xs', s: 's', m: 'm', l: 'l', xl: 'xl' };

export const textTransform = {
  none: 'none',
  capitalize: 'capitalize',
  uppercase: 'uppercase',
  lowercase: 'lowercase',
};

export const fonts = {
  primary: '"ALS Wagon", "Open Sans", "Helvetica Neue", sans-serif',
  secondary: '"ALS Hauss", "Open Sans", "Helvetica Neue", sans-serif',
};

export const textAligns = {
  left: 'left',
  center: 'center',
  right: 'right',
  justify: 'justify',
};

export const fontWeights = {
  bold: 700,
  medium: 500,
  regular: 400,
  light: 300,
  thin: 100,
};

export const fontStyles = {
  normal: 'normal',
  italic: 'italic',
};

export const fontStretches = {
  condensed: 'condensed',
  semiCondensed: 'semi-condensed',
  normal: 'normal',
  expanded: 'expanded',
};

export const display = {
  block: 'block',
  inline: 'inline-block',
  none: 'none',
};

export const whiteSpace = {
  normal: 'normal',
  preWrap: 'pre-wrap',
  noWrap: 'nowrap',
};

export const colors = {
  white10: 'rgba(255, 255, 255, 0.1)',
  white16: 'rgba(255, 255, 255, 0.16)',
  white20: 'rgba(255, 255, 255, 0.2)',
  white24: 'rgba(255, 255, 255, 0.24)',
  white30: 'rgba(255, 255, 255, 0.3)',
  white32: 'rgba(255, 255, 255, 0.32)',
  white40: 'rgba(255, 255, 255, 0.4)',
  white50: 'rgba(255, 255, 255, 0.5)',
  white60: 'rgba(255, 255, 255, 0.6)',
  white64: 'rgba(255, 255, 255, 0.64)',
  white70: 'rgba(255, 255, 255, 0.7)',
  white80: 'rgba(255, 255, 255, 0.8)',
  white88: 'rgba(255, 255, 255, 0.88)',
  white90: 'rgba(255, 255, 255, 0.9)',
  white: 'rgba(255, 255, 255, 1)', // #FFFFFF

  black10: 'rgba(0, 0, 0, 0.1)',
  black20: 'rgba(0, 0, 0, 0.2)',
  black30: 'rgba(0, 0, 0, 0.3)',
  black40: 'rgba(0, 0, 0, 0.4)',
  black50: 'rgba(0, 0, 0, 0.5)',
  black56: 'rgba(0, 0, 0, 0.56)',
  black60: 'rgba(0, 0, 0, 0.6)',
  black70: 'rgba(0, 0, 0, 0.7)',
  black80: 'rgba(0, 0, 0, 0.8)',
  black90: 'rgba(0, 0, 0, 0.9)',
  black: 'rgba(0, 0, 0, 1)',

  appBlack: 'rgba(11, 11, 11, 1)', // #0B0B0B

  green10: 'rgba(0, 247, 182, 0.1)',
  green20: 'rgba(0, 247, 182, 0.2)',
  green30: 'rgba(0, 247, 182, 0.3)',
  green40: 'rgba(0, 247, 182, 0.4)',
  green50: 'rgba(0, 247, 182, 0.5)',
  green60: 'rgba(0, 247, 182, 0.6)',
  green70: 'rgba(0, 247, 182, 0.7)',
  green80: 'rgba(0, 247, 182, 0.8)',
  green90: 'rgba(0, 247, 182, 0.9)',
  green: 'rgba(0, 247, 182, 1)', // #00F7B6

  grey10: 'rgba(81, 81, 81, 0.1)',
  grey20: 'rgba(81, 81, 81, 0.2)',
  grey30: 'rgba(81, 81, 81, 0.3)',
  grey35: 'rgba(81, 81, 81, 0.35)',
  grey40: 'rgba(81, 81, 81, 0.4)',
  grey50: 'rgba(81, 81, 81, 0.5)',
  grey60: 'rgba(81, 81, 81, 0.6)',
  grey70: 'rgba(81, 81, 81, 0.7)',
  grey80: 'rgba(81, 81, 81, 0.8)',
  grey90: 'rgba(81, 81, 81, 0.9)',
  grey: 'rgba(81, 81, 81, 1)', // #515151

  disabled: '#CBCACB',

  coralRed: '#E43759',

  mediumGrey: 'rgba(153, 153, 153, 1)', // #999999

  lightGrey10: 'rgba(244, 244, 244, 0.1)',
  lightGrey20: 'rgba(244, 244, 244, 0.2)',
  lightGrey30: 'rgba(244, 244, 244, 0.3)',
  lightGrey40: 'rgba(244, 244, 244, 0.4)',
  lightGrey50: 'rgba(244, 244, 244, 0.5)',
  lightGrey60: 'rgba(244, 244, 244, 0.6)',
  lightGrey70: 'rgba(244, 244, 244, 0.7)',
  lightGrey80: 'rgba(244, 244, 244, 0.8)',
  lightGrey90: 'rgba(244, 244, 244, 0.9)',
  lightGrey: 'rgba(244, 244, 244, 1)', // #F4F4F4

  silverGrey: 'rgba(217, 217, 217, 1)', // #D9D9D9

  blue05: 'rgba(72, 1, 255, 0.05)',
  blue08: 'rgba(72, 1, 255, 0.08)',
  blue10: 'rgba(72, 1, 255, 0.1)',
  blue20: 'rgba(72, 1, 255, 0.2)',
  blue30: 'rgba(72, 1, 255, 0.3)',
  blue40: 'rgba(72, 1, 255, 0.4)',
  blue50: 'rgba(72, 1, 255, 0.5)',
  blue60: 'rgba(72, 1, 255, 0.6)',
  blue70: 'rgba(72, 1, 255, 0.7)',
  blue80: 'rgba(72, 1, 255, 0.8)',
  blue90: 'rgba(72, 1, 255, 0.9)',
  blue: 'rgba(72, 1, 255, 1)', // #4801FF

  lightRed: 'rgba(248, 248, 248, 1)', // #F8F8F8
  lightRed252: 'rgba(252, 231, 235, 1)', // #fce7eb
  lightRed237: 'rgba(237, 121, 144, 1)', // #ed7990

  red08: 'rgba(228, 55, 89, 0.08)',
  red10: 'rgba(228, 55, 89, 0.1)',
  red20: 'rgba(228, 55, 89, 0.2)',
  red30: 'rgba(228, 55, 89, 0.3)',
  red40: 'rgba(228, 55, 89, 0.4)',
  red50: 'rgba(228, 55, 89, 0.5)',
  red60: 'rgba(228, 55, 89, 0.6)',
  red70: 'rgba(228, 55, 89, 0.7)',
  red80: 'rgba(228, 55, 89, 0.8)',
  red90: 'rgba(228, 55, 89, 0.9)',
  red: 'rgba(228, 55, 89, 1)', // #E43759

  yellow88: 'rgba(250, 255, 0, 0.88)', // FAFF00
  yellow: 'rgba(250, 255, 0, 1)', // FAFF00

  transparent: 'transparent',
};

export const gradients = {
  lightGrey: 'linear-gradient(#F4F4F4, #F4F4F4)',
  greenBlue:
    'linear-gradient(87.44deg, #74F2BA 1.94%, #6CE4CF 12.28%, #62D1EE 21.68%, #57BCF9 32.52%, #4BA4F8 47.77%, #3B89F7 63%, #2864F6 80.39%, #3F20F5 97.67%)',
  greenSoftBlue:
    'linear-gradient(87.44deg, #74F2BA -14.96%, #6CE4CF 17.14%, #62D1EE 46.33%, #57BCF9 79.98%, #4BA4F8 127.31%, #3B89F7 174.58%, #2864F6 228.56%, #3F20F5 282.19%)',
  blue: 'linear-gradient(87.44deg, #74F2BA -128.21%, #6CE4CF -103.43%, #62D1EE -80.9%, #57BCF9 -54.92%, #4BA4F8 -18.38%, #3B89F7 18.11%, #2864F6 59.78%, #3F20F5 101.18%)',
};

export const paymentSystems = {
  visa: 'visa',
  mastercard: 'mastercard',
  mir: 'mir',
};
