export const URLS = {
  REFRESH_TOKEN: '/oauth/token',
};
export const mobileOs = {
  ios: 'ios',
  android: 'android',
};

export const mobileNativeEvents = {
  setBackgroundColor: 'setBackgroundColor',
  goToCatalog: 'goToCatalog',
  processAsDeepLink: 'processAsDeepLink',
  refreshToken: 'refreshToken',
  goBack: 'goBack',
};

export const channels = {
  technopark: 'technopark',
  mts: 'mts',
  maxvideo: 'maxvideo',
  mixtech: 'mixtech',
  smartorange: 'smartorange',
  paomts: 'paomts',
  dixis: 'dixis',
  avito: 'avito',
  mvideo: 'mvideo',
  tele2: 'tele2',
  smarta: 'smarta',
};

export const fastQrChannels = {
  avito: 'avito',
  mvideo: 'mvideo',
};

export const commonChannelsWithoutAuth = {
  smarta: 'smarta',
};
